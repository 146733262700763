<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <div class="listbox">
              <div class="name">自定义</div>
            </div>
            <div class="ts">{{zdytext}}</div>
            <div class="listbox2">
              <div v-if="zdyflag == false"><img src="@/assets/kgoff.png" @click="onKg" class="iconkg" /></div>
              <div v-if="zdyflag == true"><img src="@/assets/kgon.png" @click="onKg" class="iconkg" /></div>
            </div>        
          </div>
          <div v-if="zdyflag">
            <div class="item">
              <div class="listbox">
                <div class="name">
                  <span class="zdy">自定义房间</span>
                  <input type="text" v-model="roomname" class="input rr" placeholder="请输入房间名称" />                               
                </div>
              </div>     
            </div>
          </div>
          <div v-if="zdyflag === false">
            <div class="item">
              <div class="listbox">
                <div class="name">起始房间号</div>
              </div>
              <div class="listbox1">
                <div class="name">
                  <span @click="onDx(1)">
                    <img src="@/assets/dx1.png" v-if="qsdxflag" class="dxicon" />
                    <img src="@/assets/dx2.png" v-if="qsdxflag === false" class="dxicon" />
                  </span>
                  <span  @click="onDx(1)" class="txt">去4 </span>
                  <span @click="onDx(2)">
                    <img src="@/assets/dx1.png" v-if="jsdxflag" class="dxicon ll" />
                    <img src="@/assets/dx2.png" v-if="jsdxflag === false" class="dxicon ll" />
                  </span>
                  <span @click="onDx(2)" class="txt">去7</span>
                </div>             
              </div>            
              <div class="listbox">
                <div class="name">
                  <span class="txt">室</span>
                  <input type="number" v-model="roomcode" class="input" placeholder="输入数字" />               
                </div> 
              </div>       
            </div>
            <div class="item">
              <div class="listbox">
                <div class="name">终止房间号</div>
              </div>
              <div class="listbox1">
                <div class="name">
                  <span @click="onDx(3)">
                    <img src="@/assets/dx1.png" v-if="qsdxflag1" class="dxicon" />
                    <img src="@/assets/dx2.png" v-if="qsdxflag1 === false" class="dxicon" />
                  </span>
                  <span @click="onDx(3)" class="txt">去4 </span>
                  <span @click="onDx(4)">
                    <img src="@/assets/dx1.png" v-if="jsdxflag1" class="dxicon ll" />
                    <img src="@/assets/dx2.png" v-if="jsdxflag1 === false" class="dxicon ll" />
                  </span>
                  <span @click="onDx(4)" class="txt">去7</span>
                </div>             
              </div>            
              <div class="listbox">
                <div class="name">
                  <span class="txt">室</span>
                  <input type="number" v-model="roomcode1" class="input" placeholder="输入数字" />               
                </div> 
              </div>       
            </div>
          </div>
          
          <div class="item">
            <div class="listbox">
              <div class="name">所在区域</div>
            </div>
            <div class="listbox1">
              <div class="name">
                <select class="form_select" v-model="selectItem" @change="selectFn($event)">
                    <option v-for="ztitem in ztitems" :key="ztitem.id" :value="ztitem.id">{{ztitem.name}}</option>
                </select>
              </div>             
            </div>
            <!--<div class="listbox">
              <div class="name">
                <img src="@/assets/arrow.png" class="arrow" />
                <div class="text">+添加区域</div>   
              </div>           
            </div>-->        
          </div>
          <div class="item">
            <div class="listbox">
              <div class="name">所在楼层</div>
            </div>
            <div class="listbox">
              <div class="name">
                <!--<img src="@/assets/arrow.png" class="arrow" />-->
                <select class="form_select1" v-model="selectLcItem" @change="selectFn($event)">
                    <option v-for="lcitem in lcitems" :key="lcitem.id" :value="lcitem.id">{{lcitem.name}}</option>
                </select>
              </div>           
            </div>        
          </div>
          <div class="item">
          <div class="listbox">
            <div class="name">房间类型</div>
          </div>
          <div class="listbox1">
            <div class="name">
              <span @click="onDdx(1)">
                <img src="@/assets/ddx1.png" v-if="ddrflag" class="dxicon" />
                <img src="@/assets/ddx2.png" v-if="ddrflag === false" class="dxicon" />
              </span>
              <span @click="onDdx(1)" class="txt">单人 </span>
              <span @click="onDdx(2)">
                <img src="@/assets/ddx1.png" v-if="srflag" class="dxicon ll" />
                <img src="@/assets/ddx2.png" v-if="srflag === false" class="dxicon ll" />
              </span>
              <span @click="onDdx(2)" class="txt">双人</span>
            </div> 
            <div class="name">
              <span @click="onDdx(3)">
                <img src="@/assets/ddx1.png" v-if="hhflag" class="dxicon" />
                <img src="@/assets/ddx2.png" v-if="hhflag === false" class="dxicon" />
              </span> 
              <span @click="onDdx(3)" class="txt">豪华 </span>
              <select class="form_select2" v-model="selectHhItem" @change="selectFn($event)">
                <option v-for="hhitem in hhitems" :key="hhitem.id" :value="hhitem.id">{{hhitem.name}}</option>
              </select>
            </div>  
            <div class="name">
              <span @click="onDdx(4)">
              <img src="@/assets/ddx1.png" v-if="drflag" class="dxicon" />
              <img src="@/assets/ddx2.png" v-if="drflag === false" class="dxicon" />
              </span> 
              <span @click="onDdx(4)" class="txt">多人 </span>
              <select class="form_select2" v-model="selectDrItem" @change="selectFn($event)">
                <option v-for="dritem in dritems" :key="dritem.id" :value="dritem.id">{{dritem.name}}</option>
              </select>
            </div>          
          </div>            
          <div class="listbox1">
            <div class="name">
              <span @click="onDdx(5)">
              <img src="@/assets/ddx1.png" v-if="bzflag" class="dxicon" />
              <img src="@/assets/ddx2.png" v-if="bzflag === false" class="dxicon" />
              </span> 
              <span @click="onDdx(5)" class="txt">标准 </span>              
            </div> 
            <div class="name">
              <span @click="onDdx(6)">
              <img src="@/assets/ddx1.png" v-if="swflag" class="dxicon" />
              <img src="@/assets/ddx2.png" v-if="swflag === false" class="dxicon" />
              </span> 
              <span @click="onDdx(6)" class="txt">商务 </span>              
            </div>
            <div class="name">
              <span @click="onDdx(7)">
              <img src="@/assets/ddx1.png" v-if="jtflag" class="dxicon" />
              <img src="@/assets/ddx2.png" v-if="jtflag === false" class="dxicon" />
              </span> 
              <span @click="onDdx(7)" class="txt">家庭 </span>              
            </div>
          </div>       
        </div>
        <div class="item">
          <div class="listbox">
            <div class="name">房间设施</div>
          </div>
          <div class="listbox1">
            <div class="name">
              <span @click="onDx(5)">
                <img src="@/assets/dx1.png" v-if="rsqflag" class="dxicon" />
                <img src="@/assets/dx2.png" v-if="rsqflag === false" class="dxicon" />
              </span>
              <span @click="onDx(5)" class="txt">热水器 </span>
            </div> 
            <div class="name">
              <span @click="onDx(6)">
                <img src="@/assets/dx1.png" v-if="wsjflag" class="dxicon" />
                <img src="@/assets/dx2.png" v-if="wsjflag === false" class="dxicon" />
              </span>
              <span @click="onDx(6)" class="txt">卫生间</span>
            </div>            
          </div>            
          <div class="listbox1">
            <div class="name">
              <span @click="onDx(7)">
                <img src="@/assets/dx1.png" v-if="ktflag" class="dxicon" />
                <img src="@/assets/dx2.png" v-if="ktflag === false" class="dxicon" />
              </span>
              <span @click="onDx(7)" class="txt">空调</span>
            </div>  
            <div class="name">
              <span @click="onDx(8)">
                <img src="@/assets/dx1.png" v-if="chflag" class="dxicon" />
                <img src="@/assets/dx2.png" v-if="chflag === false" class="dxicon" />
              </span>
              <span @click="onDx(8)" class="txt">窗户</span>
            </div>           
          </div>        
        </div>
        <div class="button primary mm" @click="submit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  data() {
    return {
      selectItem: '1',
      selectLcItem: '1',
      selectHhItem: '1',
      selectDrItem: '3',
      ztitems: [
          {id:1, name: 'A区'},
          {id:2, name: 'B区'},
          {id:3, name: 'C区'}
      ],
      lcitems: [
          {id:1, name: '1楼'},
          {id:2, name: '2楼'},
          {id:3, name: '3楼'}
      ],
      hhitems: [
          {id:1, name: '1床'},
          {id:2, name: '2床'},
          {id:3, name: '3床'}
      ],
      dritems: [
          {id:1, name: '1床'},
          {id:2, name: '2床'},
          {id:3, name: '3床'}
      ],
      roomcode: '',
      roomcode1: '',
      roomname: '',
      zdytext: '否',
      zdyflag: false,
      qsdxflag: false,
      jsdxflag: true,
      qsdxflag1: true,
      jsdxflag1: false,
      ddrflag: false,
      srflag: true,
      bzflag: false,
      hhflag: false,
      swflag: false,
      drflag: false,
      jtflag: false,
      rsqflag: true,
      ktflag: false,
      wsjflag: false,
      chflag: true,
      remark: ''
    };
  },
  methods: {
    onKg() {
      this.zdyflag=!this.zdyflag;
      if(this.zdyflag){
        this.zdytext='是';
      }else{
        this.zdytext='否';
      }
      //this.data.kgflag=!this.data.kgflag;
      //Dialog({ message: "<img src='/img/cg.png' width=100px height=60px/></br>onKg" });      
    },
    onDx(flag) {
      if(flag==1){
        this.qsdxflag=!this.qsdxflag;
      }else if(flag==2){
        this.jsdxflag=!this.jsdxflag;
      }else if(flag==3){
        this.qsdxflag1=!this.qsdxflag1;
      }else if(flag==4){
        this.jsdxflag1=!this.jsdxflag1;
      }else if(flag==5){
        this.rsqflag=!this.rsqflag;
      }else if(flag==6){
        this.wsjflag=!this.wsjflag;
      }else if(flag==7){
        this.ktflag=!this.ktflag;
      }else if(flag==8){
        this.chflag=!this.chflag;
      }      
    },
    onDdx(flag) {
      if(flag==1){
        this.ddrflag=true;
        this.srflag=false;
        this.hhflag=false;
        this.drflag=false;
        this.bzflag=false;
        this.swflag=false;
        this.jtflag=false;
      }else if(flag==2){
        this.srflag=true;
        this.ddrflag=false;
        this.hhflag=false;
        this.drflag=false;
        this.bzflag=false;
        this.swflag=false;
        this.jtflag=false;
      }else if(flag==3){
        this.hhflag=true;
        this.ddrflag=false;
        this.srflag=false;
        this.drflag=false;
        this.bzflag=false;
        this.swflag=false;
        this.jtflag=false;
      }else if(flag==4){
        this.drflag=true;
        this.ddrflag=false;
        this.srflag=false;
        this.hhflag=false;
        this.bzflag=false;
        this.swflag=false;
        this.jtflag=false;
      }else if(flag==5){
        this.bzflag=true;
        this.ddrflag=false;
        this.srflag=false;
        this.drflag=false;
        this.hhflag=false;
        this.swflag=false;
        this.jtflag=false;
      }else if(flag==6){
        this.swflag=true;
        this.ddrflag=false;
        this.srflag=false;
        this.drflag=false;
        this.bzflag=false;
        this.hhflag=false;
        this.jtflag=false;
      }else if(flag==7){
        this.jtflag=true;
        this.ddrflag=false;
        this.srflag=false;
        this.drflag=false;
        this.bzflag=false;
        this.swflag=false;
        this.hhflag=false;
      }
    },
    selectFn(e) {
      Toast(e.target.value);
      // console.log(e)
      // console.log(e.target.selectedIndex) // 选择项的index索引
      // console.log(e.target.value) // 选择项的value
    },
    async submit() {
      /*if (isEmpty(this.name)) {
        Toast('吧台名称不可以为空!');
        return;
      }
      let { data } = await this.axios.post(
        '/ctid/tlService/addDoor',
        qs.stringify({ doorname: this.name, bz: this.remark })
      );
      if (data.flag === '0') {
        Toast('添加成功!');
        this.$router.replace('/position/detail/' + data.data.client_id);
      } else {
        Dialog({ message: data.message });
      }*/
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 60px;
  padding: 10px 40px 50px 40px;
}
.listbox {
  flex: 1;
  overflow: hidden;
  .red {
    color: rgb(180, 4, 4);
  }   
  .name {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 28px;
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
    } 
    .zdy {
      float: left;
    }
    .input {
      vertical-align: middle;
      width: 125px;
      border: 0;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      float: right;
      &.rr {
        margin-left: 72px;
        float: left;
        width: 399px;
      }
    }
    .txt {
      vertical-align: middle;
      font-size: 25px;
      float: right;
      margin-left: 10px;
      margin-right: 10px;
      color: rgb(136, 134, 134);
    }
    .txt1 {
      vertical-align: middle;
      font-size: 25px; 
      color: rgb(136, 134, 134);    
    }
    .arrow {
      float: right;
      display: block;
      //margin-left: 10px;
      margin-top: 8px;
      height: 40px;
    }
    .text {
      font-size: 25px;
      float: right;
      color: rgb(136, 134, 134);
    }
    .form_select1 {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/arrow_1.png') 98% center no-repeat;
      background-size: 26px;
      width: 100%;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      text-align: right;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      float: right;
      direction: rtl;
      padding-right: 40px;
    }
    select:focus { outline: none; }
  }
  .name1 {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 35px;
    font-weight:800;
    margin-top: 52px;
    margin-left: 34px;
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
    } 
    .txt {
      vertical-align: middle;
      font-size: 25px;
      float: right;
      margin-left: 10px;
      margin-right: 10px;
      color: rgb(136, 134, 134);
    }
  }
  .unames {
    height: 30px;
    line-height: 30px;
    font-size: 28px;
    color: #666666;
    margin-top: 30px;
    margin-left: 36px;
  }
  .unames1 {
    height: 30px;
    line-height: 30px;
    font-size: 28px;
    color: #666666;
    margin-top: 10px;
    margin-left: 36px;
  }
  
}
.listbox1 {
  flex: 1;
  overflow: hidden;
  width: 50px;
  .name {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 28px;   
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
      margin-right: 6px;
      &.ll {
        margin-left: 10px;
      }
    }
    .txt {
      vertical-align: middle;
      font-size: 25px; 
      color: rgb(136, 134, 134);    
    }
    .arrow {
      float: right;
      vertical-align: middle;
      height: 40px;
    }
    .text {
      font-size: 25px;
      float: right;
    }
    .form_select {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/arrow_1.png') 98% center no-repeat;
      background-size: 26px;
      width: 100%;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      direction: rtl;
      padding-right: 40px;
    } 
    select:focus { outline: none; }

    .form_select2 {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/xl1.png') 56px center no-repeat;
      background-size: 26px;
      width: 80px;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      margin-left: 15px;
    } 
    select:focus { outline: none; } 

  }  
  .name1 {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 28px;
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
      margin-right: 6px;
      &.ll {
        margin-left: 10px;
      }
    }
    .txt {
      vertical-align: middle;
      font-size: 25px; 
      color: rgb(136, 134, 134);    
    }
  }
}

.ts {
  height: 60px;
  width: 50px;
  line-height: 60px;
  overflow: hidden;
  font-size: 25px;
  display: block;
  float: right;
  color: rgb(136, 134, 134);
}
.listbox2 {
  overflow: hidden;
  .unames {
    height: 60px;
    line-height: 60px;
    font-size: 28px;
    color: #c90c0c;
    margin-top: 22px;
    margin-right: 34px;
    float: right;
  }
  .iconkg {
    width: 80px;
    display: block;
    float: right;
  }  
     
}
</style>